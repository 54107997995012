import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Layout from 'components/layout';
import TextPage from '../components/textPage';

const query = graphql`
  query {
    termsJson {
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;

const Index = () => {
  const data = useStaticQuery(query).termsJson;
  return (
    <Layout>
      <TextPage content={data.content.childMarkdownRemark.html} />
    </Layout>
  );
};

export default Index;
